<!--
 * @Author: 矢车
 * @Date: 2020-07-24 10:24:15
 * @LastEditors: 矢车
 * @LastEditTime: 2020-08-17 16:59:27
 * @Descripttion: 结算管理 - 列表
-->

<template>
  <div id="userManager">
    <div class="header_info">
      <a-form layout="inline" :form="form">
        <div class="filter-condition-form">
          <a-form-item label="商家名称：">
            <a-input v-model="searchParams.merchant_name" allowClear placeholder="请输入商家名称" style="width:190px;" />
          </a-form-item>
          <a-form-item label="提现单号：">
            <a-input v-model="searchParams.settlement_no" allowClear placeholder="请输入提现单号" style="width:190px;" />
          </a-form-item>
          <a-form-item label="结算单发放时间：">
            <a-range-picker v-model="searchParams.issue_time" allowClear :disabled-date="disabledDate" @change="onRangeTimeChange" style="width:220px;" />
          </a-form-item>
          <a-form-item label="对账状态">
            <a-select v-model="searchParams.reconciliation_status" style="width: 120px">
              <a-select-option value="0">全部</a-select-option>
              <a-select-option value="1">未完成</a-select-option>
              <a-select-option value="2">已完成</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="handleSearch" html-type="submit" style="margin-right:20px;">查询</a-button>
            <a-button @click="handleReset">重置</a-button>
          </a-form-item>
        </div>
        <div class="mt10">
          <a-button type="primary" v-auth="'settle_accounts_manage_import_statement'" @click="$router.push('/admin/shop/leadSettle')">导入结算单</a-button>
        </div>
      </a-form>
    </div>

    <a-table :scroll="{x: 1200, y: tableScrollY }" :rowKey="record => record.id" :columns="SettlementColumn" :dataSource="list" :pagination="pagination" :locale="{ filterConfirm: '暂无数据' }" @change="handleTableChange">
      <template slot="timeStampFormat" slot-scope="record">
        {{record | dateFilter}}
      </template>
      <template slot="reconciliation_time" slot-scope="record">
        {{(record | dateFilter) || '--'}}
      </template>
      <template slot="reconciliation_status" slot-scope="record">
        <span style="color: red" v-if="record==1">未完成</span>
        <span style="color: green" v-else>已完成</span>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button type="primary" size="small" @click="clickDetail(record)"> 查看明细 </a-button>
      </template>
    </a-table>

    <template>
      <a-drawer :destroyOnClose="true" title="查看明细" :width="1300" :visible="drawerSetterDetail.isShow" :body-style="{ paddingBottom: '80px' }" @close="onDetailClose">
        <settleDetail @showSpin="isShowSpin" :props_row="rowData"></settleDetail>
      </a-drawer>
    </template>
    <div id="estop" v-if="isShow">
      <a-spin size="large" tip="正在导出明细，请稍后 . . ." />
    </div>
  </div>
</template>

<script>
import { settleDetail } from "@/components/admin/shop";
import { SettlementColumn } from "@/const/shop";
import { settlementList } from "@/service/shop";
import moment from "moment";

export default {
  components: {
    settleDetail,
  },
  data() {
    return {
      tableScrollY: 600,
      form: this.$form.createForm(this),
      SettlementColumn,
      list: [],
      rowData: {},
      isShow: false,
      searchParams: {
        merchant_name: "",
        settlement_no: "",
        issue_time: "",
        start_issue_time: "",
        end_issue_time: "",
        reconciliation_status: "0",
      },
      drawerSetterDetail: {
        isShow: false,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total) =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize),
      },
    };
  },
  created() {
    this.getList();
    localStorage.removeItem("isRefresh");
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 370;
  },
  methods: {
    /**
     * @Author: 矢车
     * @Date: 2020-07-30 15:06:49
     * @Description: 获取列表数据
     */
    async getList(
      merchant_name,
      settlement_no,
      start_issue_time,
      end_issue_time,
      reconciliation_status
    ) {
      const { res } = await settlementList({
        merchant_name,
        settlement_no,
        start_issue_time,
        end_issue_time,
        reconciliation_status,
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
      });
      if (res.error_code === 0) {
        this.list = res.data.rows.map((item) => {
          return {
            ...item,
            settlement_amountFormat: item.settlement_amount / 100,
          };
        });
        const pagination = { ...this.pagination };
        pagination.total = res.data.total;
        this.pagination = pagination;
      }
    },

    /**
     * @Author: 矢车
     * @Date: 2020-07-30 15:29:07
     * @Description: 范围时间选择方法
     */
    onRangeTimeChange(date, dateString) {
      if (!dateString[0] || !dateString[1]) {
        this.searchParams.start_issue_time = "";
        this.searchParams.end_issue_time = "";
        return;
      }

      this.searchParams.start_issue_time = new Date(`${dateString[0]} 00:00:00`)
        .getTime()
        .toString()
        .substr(0, 10);
      this.searchParams.end_issue_time = new Date(`${dateString[1]} 23:59:59`)
        .getTime()
        .toString()
        .substr(0, 10);
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-05 20:14:02
     * @Description: 只能选择到当前时间为止
     */
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },

    /**
     * @Author: 矢车
     * @Date: 2020-07-31 16:37:21
     * @Description: 点击表格进入查看明细
     */
    clickDetail(record) {
      this.rowData = record;
      this.drawerSetterDetail.isShow = true;
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-10 17:44:41
     * @Description: 导出结算单的loading状态
     */
    isShowSpin(isBool) {
      this.isShow = isBool;
    },

    /**
     * @Author: 矢车
     * @Date: 2020-07-30 15:49:08
     * @Description: 点击查询
     */
    handleSearch() {
      this.pagination.current = 1;
      this.getList(
        this.searchParams.merchant_name || null,
        this.searchParams.settlement_no || null,
        this.searchParams.start_issue_time || null,
        this.searchParams.end_issue_time || null,
        this.searchParams.reconciliation_status || "0"
      );
    },

    /**
     * @Author: 矢车
     * @Date: 2020-07-30 15:56:07
     * @Description: 重置表单
     */
    handleReset() {
      this.pagination.current = 1;
      this.form.resetFields();
      this.searchParams.merchant_name = "";
      this.searchParams.settlement_no = "";
      this.searchParams.start_issue_time = "";
      this.searchParams.end_issue_time = "";
      this.searchParams.issue_time = "";
      this.searchParams.reconciliation_status = "0";
      this.getList();
    },

    // 跳页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getList(
        this.searchParams.merchant_name || null,
        this.searchParams.settlement_no || null,
        this.searchParams.start_issue_time || null,
        this.searchParams.end_issue_time || null,
        this.searchParams.reconciliation_status || "0"
      );
    },

    onDetailClose() {
      this.drawerSetterDetail.isShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
  #userManager{
    /deep/ .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
    .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
  }
#estop {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.header_info {
  line-height: 40px;
  margin-bottom: 15px;
}
</style>

<style scoped>
>>> .ant-spin-dot-item {
  background-color: white;
  opacity: 1;
}
>>> .ant-spin-text {
  font-size: 18px;
  margin-top: 10px;
  color: white;
}
</style>

