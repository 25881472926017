import { render, staticRenderFns } from "./settlement-management.vue?vue&type=template&id=379a14fd&scoped=true&"
import script from "./settlement-management.vue?vue&type=script&lang=js&"
export * from "./settlement-management.vue?vue&type=script&lang=js&"
import style0 from "./settlement-management.vue?vue&type=style&index=0&id=379a14fd&prod&lang=less&scoped=true&"
import style1 from "./settlement-management.vue?vue&type=style&index=1&id=379a14fd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "379a14fd",
  null
  
)

export default component.exports